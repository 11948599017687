.neu-toast {
  &--error {
    background-color: #f9dbde;
    color: #960b1c;
    border: 1px solid #960b1c;

    .Toastify__close-button--error {
      color: #960b1c !important;
    }
  }

  &--warn {
    background-color: #fff1d0;
    color: #6f4705;
    border: 1px solid #6f4705;

    .Toastify__close-button--warning {
      color: #6f4705 !important;
    }
  }

  &--success {
    background-color: #e8f5d7;
    color: #285c4d;
    border: 1px solid #285c4d;

    .Toastify__close-button--success {
      color: #285c4d !important;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    -webkit-align-items: center;
  }
}

.Toastify__toast-container {
  min-width: 320px;
}
